<template>
	<ion-page>
		<A-header title="My projects" :enableNew="lists.length > 0" @on-new="onCreateList()" />
		
		<ion-content :fullscreen="true">
			<A-collapse-header title="My projects" />
		
			<ion-card v-for="list in lists" :key="`shopping-list-${list.id}`" @click="$router.push(`/list/${list.id}`)">
				<ion-card-header>
					<ion-card-title>{{ list.title }}</ion-card-title>
				</ion-card-header>

				<ion-card-content>
					<ion-list lines="none">
						<ion-item v-for="item in list.items" :key="`item-${list.id}-${item.id}`">
							<ion-label :class="{'is-checked': item.is_checked}">{{ item.name }}</ion-label>
							<ion-badge :class="`priority-${item.priority}`">{{ priorities[item.priority] }}</ion-badge>
						</ion-item>
					</ion-list>

					<ion-label v-if="list._numberOfItems > 3">And {{ list._numberOfItems - 3 }} others</ion-label>
					<ion-label v-else-if="!list.items.length">No items</ion-label>
				</ion-card-content>
			</ion-card>

			<ion-card>
				<ion-button v-if="!lists.length" color="primary" expand="block" @click="onCreateList()">
					<ion-icon slot="start" :icon="add" />
					Add project
				</ion-button>
			</ion-card>
			
			<A-footer-safearea />
		</ion-content>
	</ion-page>
</template>
<script>
import AHeader 			from '@/plugins/appshoppinglist/layout/a-header.vue'
import ACollapseHeader 	from '@/plugins/appshoppinglist/layout/a-collapse-header.vue'
import AFooterSafearea  from '@/plugins/appshoppinglist/layout/a-footer-safearea.vue'
import { add } 			from 'ionicons/icons'
import { alertController } from "@ionic/vue"

export default {
	components: {
		AHeader,
		ACollapseHeader,
		AFooterSafearea
	},

	data() {
		return {
			add,
			priorities: {
				0: 'None',
				1: 'Optional',
				2: 'Should',
				3: 'Normal',
				4: 'Important',
				5: 'High priority',
			}
		}
	},

	computed: {
		lists() {
			let lists = []

			this.$store.getters['shoppingList/lists'].forEach(list => {
				let items 	= [...list.items.filter(item => !item.is_checked), ...list.items.filter(item => item.is_checked)]
				items 		= items.slice(0, 3)

				lists.push({
					id: list.id,
					title: list.title,
					items: items,
					_numberOfItems: list.items.length
				})
			})

			return lists
		}
	},

	ionViewWillEnter() {
		this.$store.dispatch('shoppingList/loadLists')
	},

	methods: {
		async onCreateList() {
			const alert = await alertController.create({
				header: 'Project',
				inputs: [{
					name: 'title',
					type: 'text',
					placeholder: 'Webcities',
				}],
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},
				{
					text: 'Create',
					handler: async(value) => {
						await this._createList(value.title)
					}
				}]
			})

			return alert.present()
		},

		async _createList(title) {
			try {
				const shoppingList = await this.$store.dispatch('shoppingList/createList', {
					title: title.length ? title : 'Unnamed list',
					items: []
				})

				this.$router.push({name: 'List detail', params: {id: shoppingList.id}})
			} catch (error) {
				console.log(error)
			}
		}
	}
}
</script>

<style lang="sass" scoped>
ion-list
	margin-bottom: 15px
	
	ion-item
		--background: var(--ion-card-background)
		--padding-start: 0

		.is-checked
			text-decoration: line-through

</style>